/*
@File: Lizel Template Style

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Home One CSS
** - Home Two CSS
** - Home Three CSS
** - Banner CSS
** - Feature CSS
** - Process CSS
** - Service CSS
** - Project CSS
** - Choose CSS
** - Virtual CSS
** - Help CSS
** - Testimonial CSS
** - Blog CSS
** - Logo CSS
** - Footer CSS
** - Copyright CSS
** - Preloader CSS
** - Back to Top CSS
** - Team CSS
** - Shop CSS
** - Support CSS
** - Price CSS
** - Contact CSS
** - Cart CSS
** - Checkout CSS
** - Sign Up CSS
** - Gallery CSS
** - FAQ CSS
** - 404 Error Page CSS
** - Coming Soon CSS
** - Privacy Policy CSS
*/

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap');

$common-font: 'Roboto', sans-serif;
$heading-font: 'Montserrat', sans-serif;
$transition: .5s all ease;
$all-size: 16px;
$white-color: #ffffff;
$grey-color: #5d5d5d;
$black-color: #02334f;

$yellow-color: #fdf288;

$blue-color: #162c54;
$green-color: #63bc46;
$main-color: $blue-color;
$accent-color: $green-color;

/*-- Default CSS --*/
body {
    font-family: $common-font;
    color: $grey-color;
    background-color: #fff;
    font-size: $all-size;
}
h1, h2, h3, h4, h5, h6 {
    line-height: 1.3;
    font-family: $heading-font;
}
p {
    line-height: 1.8;
}
a {
    transition: $transition;
    text-decoration: none;
}
img {
    max-width: 100%;
}
.d-table {
    width:100%;
    height:100%;
}
.d-table-cell {
    vertical-align: middle;
}
.ptb-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}
.pt-100 {
    padding-top: 100px;
}
.pb-70 {
    padding-bottom: 70px;
}
.pb-100 {
    padding-bottom: 100px;
}
button {
    &:focus{
        outline: 0;
    }
}
.btn.focus, .btn:focus {
    box-shadow: none;
}
.container {
    max-width: 1280px;
}
/*-- End Default CSS --*/

/*----- Header CSS -----*/
.header-top-area {
    background-color: $main-color;
    padding-top: 15px;
    padding-bottom: 15px;

    .header-item {
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;
                margin-right: 15px;

                &:last-child {
                    margin-right: 0;
                }
                i {
                    display: inline-block;
                    color: $accent-color;
                    font-size: 14px;
                    margin-right: 3px;
                }
                a {
                    display: inline-block;
                    color: $white-color;
                    font-size: 14px;

                    &:hover {
                        color: $accent-color;
                    }
                }
                span {
                    display: inline-block;
                    font-size: 14px;
                    color: $white-color;
                }
            }
        }
    }
    .header-right {
        text-align: right;
    }
}
/*----- End Header CSS -----*/

/*----- Navbar CSS -----*/
/*-- Home One --*/
.navbar-light {
    padding-left: 0;
    padding-right: 0;
}
.main-nav {
    background-color: $main-color;
    padding-top: 5px;
    padding-bottom: 5px;
    transition: $transition;

    nav {
        .navbar-nav {
            margin-left: auto;
            margin-right: auto;

            .nav-item {
                &:hover {
                    ul {
                        transform: scaleX(1);
                    }
                    a {
                        color: $accent-color;
                    }
                }
                a {
                    font-weight: 600;
                    font-family: $heading-font;
                    text-transform: capitalize;
                    transition: $transition;

                    &:hover, &:focus, &.active {
                        color: $accent-color;
                    }
                    i {
                        display: inline-block;
                        font-size: 20px;
                        position: relative;
                        top: 5px;
                    }
                }
                .dropdown-menu {
                    padding-top: 5px;
                    padding-bottom: 5px;
                    transform: scaleX(0);
                    background-color: $white-color;
                    padding-right: 0;
                    padding-left: 0;

                    li {
                        border-bottom: 1px solid #fec9392b;

                        &:last-child {
                            border-bottom: 0;
                        }
                        &:hover {
                            .dropdown-menu {
                                top: -6px !important;
                            }
                            a {
                                color: #fec939;
                            }
                        }
                        a {
                            color: $black-color;

                            &:hover, &:focus, &.active {
                                color: #fec939;
                                letter-spacing: 1px;
                            }
                        }
                        .dropdown-menu {
                            right: -100%;
                            left: unset;

                            li {
                                a {
                                    color: $black-color;

                                    &:hover, &:focus, &.active {
                                        color: #fec939;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .dropdown-toggle::after {
        display: none;
    }
    &.menu-shrink {
        background-color: $main-color;
        padding-top: 0;
        padding-bottom: 0;
    }
}
.side-nav {
    .cart {
        position: relative;
        top: 3px;
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        text-align: center;
        font-size: 20px;
        color: $white-color;
        background-color: $black-color;
        margin-right: 15px;

        i {
            line-height: 40px;
        }
        span {
            display: inline-block;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            position: absolute;
            top: -5px;
            right: -4px;
            font-size: 12px;
            background-color: $white-color;
            color: $black-color;
            font-weight: 500;
        }
        &:hover {
            color: $black-color;
            background-color: $accent-color;
        }
    }
    .modal-btn {
        padding: 0;
        transform: rotate(180deg);
        position: relative;
        top: -3px;

        &:hover {
            span {
                &:nth-child(2) {
                    width: 25px;
                }
                &:nth-child(3) {
                    width: 15px;
                }
            }
        }
        span {
            display: block;
            width: 30px;
            height: 3px;
            background-color: #fff;
            margin-bottom: 5px;
            transition: $transition;
        }
    }
    .nav-btn {
        display: inline-block;
        color: $black-color;
        background-color: $accent-color;
        border-radius: 30px;
        font-weight: 500;
        font-size: 16px;
        padding: 10px 20px;
        margin-left: 10px;

        &:hover {
            background-color: $white-color;
            color: $black-color;
        }
    }
}
.modal-body {
    padding: 40px 30px 50px;

    h2 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 12px;
        color: #000;
    }
    p {
        color: #545555;
        font-size: 14px;
        margin-bottom: 20px;
    }
    .image-area {
        margin-bottom: 10px;

        .col-lg-4 {
            padding-right: 5px;
            margin-right: -5px;
        }
        a {
            display: block;
            margin-bottom: 15px;

            img {
                width: 100%;
            }
        }
    }
    .modal-item {
        margin-bottom: 30px;
    }
    .social-area {
        text-align: center;

        h3 {
            font-size: 20px;
            margin-bottom: 12px;
            font-weight: 600;
            color: #000;
        }
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;

                a {
                    display: block;
                    color: $black-color;
                    border: 1px solid $black-color;
                    width: 32px;
                    height: 32px;
                    line-height: 34px;
                    border-radius: 50%;
                    margin-right: 3px;
                    margin-left: 3px;
                    font-size: 16px;
                    text-align: center;

                    &:hover {
                        color: $white-color;
                        background-color: $black-color;
                    }
                }
            }
        }
    }
}
#myModalRight {
    z-index: 99999;
    padding-right: 0 !important;

    .modal-header{
        .btn-close {
            &:focus {
                box-shadow: none;
            }
        }
    }
}
.modal {
    &.modal-right {
        .modal-dialog {
            max-width: 380px;
            min-height: 100vh;
        }
        &.show .modal-dialog {
            transform: translate(0, 0);
        }
        .modal-content {
            height: 100vh;
            overflow-y: auto;
            border-radius: 0;
        }
    }
    &.modal-left {
        .modal-dialog {
        transform: translate(-100%, 0);
        margin: 0 auto 0 0;
        }
    }
    &.modal-right {
        .modal-dialog {
        transform: translate(100%, 0);
        margin: 0 0 0 auto;
        }
    }
}

/*-- Home Two --*/
.navbar-area-two {
    .main-nav {
        background-color: transparent;

        &.menu-shrink {
            background-color: $main-color;
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}
/*----- End Navbar CSS -----*/

/*----- Banner CSS -----*/
/*-- Home One --*/
.banner-area {
    background-image: url('../img/home1.jpg');
    background-size: cover;
    background-position: 5% 25%;
    height: 550px;
    position: relative;
    overflow: hidden;
    .banner-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0,0,0,0.5)
    }
    .banner-shape {
        img {
            position: absolute;

            &:nth-child(1) {
                left: 50px;
                top: 180px;
                animation: banner-ani-one 25s infinite linear;
            }
            &:nth-child(2) {
                left: 40%;
                top: 180px;
                animation: banner-ani-two 25s infinite linear;
            }
            &:nth-child(3) {
                left: 50px;
                bottom: 220px;
                animation: banner-ani-three 25s infinite linear;
            }
            &:nth-child(4) {
                left: 310px;
                bottom: 180px;
                animation: banner-ani-four 25s infinite linear;
            }
            &:nth-child(5) {
                left: 40%;
                bottom: 200px;
                animation: banner-ani-five 30s infinite linear;
            }
            &:nth-child(6) {
                left: 0;
                right: 0;
                top: 0;
                animation: banner-ani-six 5s infinite linear;
                z-index: 0;
            }
        }
    }
    .container-fluid {
        padding-left: 0;
        padding-right: 0;

        .col-lg-6 {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .slider-img-one {
        background-image: url('../img/home-one/banner/banner-main1.jpg');
    }
    .slider-img-two {
        background-image: url('../img/home-one/banner/banner-main2.jpg');
    }
    .slider-img-three {
        background-image: url('../img/home-one/banner/banner-main3.jpg');
    }
    .slider-item {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        position: relative;
        height: 1000px;
        z-index: 2;

        img {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
        }
    }
    .owl-theme .owl-nav {
        margin-top: 0;
    }
    .banner-slider {
        &:hover {
            .owl-prev {
                opacity: 1;
                left: 55px;
            }
            .owl-next {
                opacity: 1;
                right: 10px;
            }
        }
        .owl-prev {
            width: 45px;
            height: 45px;
            font-size: 35px !important;
            border-radius: 50% !important;
            color: $accent-color !important;
            background-color: $white-color !important;
            transition: $transition;
            position: absolute;
            top: 45%;
            left: 45px;
            opacity: 0;
            
            i {
                line-height: 45px;
            }
            &:hover {
                color: $white-color;
                background-color: $black-color;
            }
        }
        .owl-next {
            width: 45px;
            height: 45px;
            font-size: 35px !important;
            border-radius: 50% !important;
            color: $accent-color !important;
            background-color: $white-color !important;
            transition: $transition;
            position: absolute;
            top: 45%;
            right: 0;
            opacity: 0;
            
            i {
                line-height: 45px;
            }
            &:hover {
                color: $white-color;
                background-color: $black-color;
            }
        }
    }
}
.banner-content {
    max-width: 625px;
    margin-left: auto;
    padding-right: 20px;
    margin-top: 20px;
    position: relative;
    z-index: 1;

    span {
        display: block;
        font-weight: 600;
        font-size: 18px;
        color: #fccb7d;
        font-family: $heading-font;
        margin-bottom: 15px;
    }
    h1 {
        margin-bottom: 5px;
        line-height: 0.75;
        font-weight: 900;
        font-size: 50px;
        color: $white-color;
    }
    p {
        margin-bottom: 30px;
        color: $white-color;
    }
    .banner-btn {
        display: inline-block;
        font-weight: 500;
        color: $white-color;
        background-color: $black-color;
        padding: 12px 25px;
        border-radius: 20px;
        position: relative;
        z-index: 1;
        overflow: hidden;
        margin-left: 20px;

        &:before {
            position: absolute;
            content: '';
            width: 100%;
            height: 0%;
            top: 50%;
            left: 0;
            right: 0;
            background-color: $accent-color;
            z-index: -1;
            transition: $transition;
        }
        &:after {
            position: absolute;
            content: '';
            width: 100%;
            height: 0%;
            bottom: 50%;
            left: 0;
            right: 0;
            background-color: $accent-color;
            z-index: -1;
            transition: $transition;
        }
        &:hover {
            color: $black-color;
    
            &:before {
                height: 50%;
            }
            &:after {
                height: 50%;
            }
        }
    }
}
.cmn-btn {
    display: inline-block;
    font-weight: 500;
    color: $black-color;
    background-color: $accent-color;
    padding: 12px 25px;
    border-radius: 20px;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 0%;
        top: 50%;
        left: 0;
        right: 0;
        background-color: $black-color;
        z-index: -1;
        transition: $transition;
    }
    &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 0%;
        bottom: 50%;
        left: 0;
        right: 0;
        background-color: $black-color;
        z-index: -1;
        transition: $transition;
    }
    &:hover {
        color: $white-color;

        &:before {
            height: 50%;
        }
        &:after {
            height: 50%;
        }
    }
}
@keyframes banner-ani-one {
    0% {
        transform: translate(0, 0);
    }
    30% {
        transform: translate(80px, -80px);
    }
    70% {
        transform: translate(80px, 80px);
    }
    100% {
        transform: translate(0, 0);
    }
}
@keyframes banner-ani-two {
    0% {
        transform: translate(0, 0);
    }
    30% {
        transform: translate(-80px, 80px);
    }
    70% {
        transform: translate(150px, 100px);
    }
    100% {
        transform: translate(0, 0);
    }
}
@keyframes banner-ani-three {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes banner-ani-four {
    0% {
        transform: translate(0, 0);
    }
    30% {
        transform: translate(50px, -50px);
    }
    70% {
        transform: translate(120px, 70px);
    }
    100% {
        transform: translate(0, 0);
    }
}
@keyframes banner-ani-five {
    0% {
        transform: translate(0, 0);
    }
    30% {
        transform: translate(120px, -120px);
    }
    70% {
        transform: translate(50px, 80px);
    }
    100% {
        transform: translate(0, 0);
    }
}
@keyframes banner-ani-six {
    0% {
        opacity: 1;
    }
    50% {
        opacity: .10;
    }
    100% {
        opacity: 1;
    }
}

/*-- Home Two --*/
.banner-area-two {
    background-image: url('../img/home-two/banner-bg.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #000;
        opacity: .40;
    }
    .banner-content {
        margin-left: 0;

        span {
            color: $white-color;
        }
        h1 {
            font-size: 53px;
        }
    }
}

/*-- Home Three --*/
.banner-area-three {
    .row {
        margin-top: -60px;
    }
    .banner-shape {
        img {
            &:nth-child(7) {
                top: 70px;
                left: 0;
            }
            &:nth-child(8) {
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
            }
        }
    }
    .banner-content {
        max-width: 100%;
        margin-left: 0;
        padding-right: 0;
        margin-top: 0;

        h1 {
            font-size: 53px;
        }
        span {
            color: $white-color;
        }
    }
}
/*----- End Banner CSS -----*/

/*----- Feature CSS -----*/
/*-- Feature One --*/
.section-title {
    text-align: center;
    margin-bottom: 40px;

    .sub-title {
        font-weight: 500;
        font-size: 18px;
        color: $main-color;
        margin-bottom: 8px;
        display: block;
    }
    h2 {
        font-weight: 700;
        font-size: 38px;
        color: $black-color;
        margin-bottom: 0;
    }
}
.feature-area {
    .section-title {
        margin-bottom: 30px;
        text-align: left;
    }
    .feature-img {
        margin-bottom: 30px;
        position: relative;
        
        .feature-main {
            position: relative;

            &:before {
                position: absolute;
                content: "";
                bottom: 0;
                right: 0;
                border-left: 500px solid transparent;
                border-bottom: 155px solid $white-color;
                width: 100%;
            }
            img {
                width: 100%;
                max-width: 520px;
                border-top: 10px solid $accent-color;
                padding-top: 10px;
                padding-right: 10px;
                border-right: 10px solid $accent-color;
            }
        }
        img {
            &:nth-child(2) {
                position: absolute;
                bottom: 10px;
                left: 140px;
                animation: feature-ani-one 20s infinite linear;
            }
            &:nth-child(3) {
                position: absolute;
                bottom: 55px;
                right: 45px;
                animation: feature-ani-two 30s infinite linear;
            }
        }
        .feature-inner {
            background-color: $accent-color;
            text-align: center;
            max-width: 130px;
            padding: 15px 20px 5px;
            position: absolute;
            top: 245px;
            right: -40px;
            box-shadow: 0px 0px 20px 0px #dddddd70;

            &:before {
                position: absolute;
                content: "";
                bottom: -20px;
                right: 0;
                border-left: 100px solid transparent;
                border-top: 20px solid $accent-color;
                width: 100%;
            }
            h3 {
                font-weight: 600;
                font-size: 48px;
                color: $main-color;
                margin-bottom: 0;
            }
            span {
                display: block;
                font-weight: 600;
                font-size: 18px;
                color: $main-color;
                font-family: $heading-font;
                margin-top: -5px;
                text-align: right;
            }
        }
    }
    .feature-content {
        padding-left: 60px;
        margin-bottom: 30px;

        p {
            margin-bottom: 10px;
            max-width: 600px;
        }
        ul {
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            margin-top: 20px;

            li {
                list-style-type: none;
                display: inline-block;
                flex: 0 0 50%;
                max-width: 50%;
                background-color: #fdfbe6;
                font-weight: 500;
                font-size: 17px;
                color: $black-color;
                max-width: 260px;
                margin-right: 30px;
                margin-bottom: 20px;
                padding: 8px;
                border-radius: 20px;

                &:hover {
                    i {
                        color: $white-color;
                        background-color: $black-color;
                    }
                }
                i {
                    display: inline-block;
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    border-radius: 50%;
                    text-align: center;
                    font-size: 20px;
                    color: $black-color;
                    background-color: $accent-color;
                    margin-right: 10px;
                    margin-left: 10px;
                    transition: $transition;
                }
            }
        }
        .cmn-btn {
            margin-top: 25px;
        }
    }
}
@keyframes feature-ani-one {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(80px, -60px);
    }
    100% {
        transform: translate(0, 0);
    }
}
@keyframes feature-ani-two {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(-190px, 50px);
    }
    100% {
        transform: translate(0, 0);
    }
}
/*-- Feature Two --*/
.feature-area-two {
    padding-bottom: 125px;

    .feature-img {
        margin-bottom: 0;

        img {
            &:nth-child(2) {
                position: absolute;
                bottom: -90px;
                left: 20px;            
                animation: none;
            }
            &:nth-child(3) {
                position: absolute;
                top: 75px;
                right: 30px;            
                bottom: unset;
                animation: none;
            }
            &:nth-child(4) {
                position: absolute;
                bottom: -170px;
                right: 0;
                max-width: 340px;
            }
        }
        .feature-main {
            &:before {
                display: none;
            }
            img {
                width: unset;
                max-width: 345px;
                padding-top: 0;
                padding-right: 0;
                border: 0;
            }
        }
        .feature-inner {
            top: 200px;
            right: 120px;
        }
    }
    .feature-content {
        padding-left: 0;
        margin-bottom: 0;

        .feature-p {
            color: #02334f;
            font-weight: 500;
        }
        ul {
            li {
                background-color: $white-color;
                padding: 0;
            }
        }
    }
}
/*----- End Feature CSS -----*/

/*----- Process CSS -----*/
.process-shape {
    img {
        position: absolute;

        &:nth-child(1) {
            top: 0;
            right: 0;
            height: 100%;
            animation: banner-ani-six 5s infinite linear;
        }
        &:nth-child(2) {
            top: 0;
            left: 0;
            height: 100%;
            animation: banner-ani-six 5s infinite linear;
        }
    }
}
.process-area {
    background-color: #fcfaea;
    position: relative;
}
.process-item {
    text-align: center;
    position: relative;
    margin-bottom: 30px;

    &:hover {
        .icon {
            i {
                color: $black-color;
                background-color: #fcf18b;
                box-shadow: none;
            }

            span {
                background-color: $white-color;
            }
        }
    }
    .icon {
        position: relative;
        display: inline-block;
        margin-bottom: 20px;

        i {
            display: inline-block;
            width: 180px;
            height: 180px;
            line-height: 180px;
            border-radius: 50%;
            color: #e4d654;
            font-size: 85px;
            background-color: $white-color;
            box-shadow: 0px 0px 20px 0px #dddddd70;
            transition: $transition;
        }
        span {
            display: inline-block;
            width: 45px;
            height: 45px;
            line-height: 45px;
            border-radius: 50%;
            color: $black-color;
            background-color: $accent-color;
            font-weight: 700;
            font-size: 20px;
            position: absolute;
            bottom: 25px;
            right: -10px;
            box-shadow: 0px 0px 20px 0px #dddddd70;
            transition: $transition;
        }
    }
    h3 {
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 12px;
        color: $black-color;
    }
    p {
        margin-bottom: 0;
        padding-left: 20px;
        padding-right: 20px;
    }
}
/*----- End Process CSS -----*/

/*----- Service CSS -----*/
/*-- Home One --*/
.service-item {
    border-radius: 10px;
    background-color: #f7f5e1;
    padding: 20px 15px;
    transition: $transition;
    margin-bottom: 30px;

    &:hover {
        background-color: $main-color;
        transform: translate(0,  -10px);

        .service-bottom {
            h3 {
                a {
                    color: $white-color;
                }
            }
            p {
                color: $white-color;
            }
            .cmn-btn {
                color: $black-color;
                background-color: $accent-color;

                &:hover {
                    color: $white-color;
                }
            }
        }
    }
    .service-top {
        position: relative;

        a {
            display: block;
           
            img {
                width: 100%;
                border-radius: 10px;
            }
        }
        i {
            display: inline-block;
            width: 55px;
            height: 55px;
            line-height: 55px;
            text-align: center;
            font-size: 25px;
            color: $black-color;
            background-color: $accent-color;
            border-radius: 50%;
            position: absolute;
            right: 15px;
            bottom: -25px;
        }
    }
    .service-bottom {
        h3 {
            margin-top: 30px;
            font-weight: 600;
            font-size: 24px;
            margin-bottom: 10px;

            a {
                display: block;
                color: $black-color;

                &:hover {
                    letter-spacing: 1px;
                }
            }
        }
        p {
            margin-bottom: 15px;
            transition: $transition;
        }
        .cmn-btn {
            color: $white-color;
            background-color: $main-color;
            padding: 12px 17px 12px 20px;

            i {
                display: inline-block;
                font-size: 18px;
                position: relative;
                top: 3px;
                animation: unset;
            }
            &:hover {
                i {
                    animation: service-btn-rotate 1s infinite linear;
                }
            }
        }
    }
}
@keyframes service-btn-rotate {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/*-- Home Two --*/
.service-area-two {
    position: relative;
    background-color: #fcfaea;

    .service-item {
        padding: 0;
        background-color: $white-color;

        &:hover {
            background-color: $main-color;
        }
        .service-bottom {
            padding: 0 20px 20px;
        }
    }
}

/*-- Home Three --*/
.service-area-three {
    position: relative;
    background-color: #fcfaea;
}
.service-item-two {
    position: relative;
    padding: 25px 30px 25px 100px;
    margin-bottom: 30px;
    transition: $transition;
    border: 1px solid #00000017;
    border-radius: 5px;


    &:hover {
        background-color: $main-color;

        .icon {
            border: 8px solid $accent-color;
        }
        h3 {
            a {
                color: $white-color;
            }
        }
        p {
            color: $white-color;
        }
        .service-link {
            color: $white-color;
        }
    }
    .icon {
        width: 60px;
        height: 60px;
        line-height: 45px;
        border-radius: 50%;
        font-size: 22px;
        text-align: center;
        color: $main-color;
        background-color: $white-color;
        position: absolute;
        top: 25px;
        left: 20px;
        border: 8px solid $white-color;
        transition: $transition;
    }
    h3 {
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 8px;

        a {
            color: $black-color;

            &:hover {
                letter-spacing: 1px;
            }
        }
    }
    p {
        margin-bottom: 8px;
        transition: $transition;
    }
    .service-link {
        font-weight: 500;
        color: $main-color;
        display: inline-block;
        font-size: 15px;

        i {
            display: inline-block;
            font-size: 17px;
            position: relative;
            top: 3px;
            animation: unset;
        }
        &:hover {
            i {
                animation: service-btn-rotate 1s infinite linear;
            }
        }
    }
}

/*-- Services --*/
.right-sidebar {
    .right-head {
        margin-bottom: 30px;

        h3 {
            font-weight: 500;
            font-size: 20px;
            color: $black-color;
            margin-bottom: 20px;
            font-family: $common-font;
        }
    }
    .search {
        form {
            position: relative;

            .form-control {
                border-radius: 0;
                background-color: $white-color;
                box-shadow: 0px 0px 20px 0px #dddddd70;
                border: 0;
                height: 50px;
                padding-left: 20px;
                font-size: 15px;

                &:focus {
                    border: 0;
                    box-shadow: 0px 0px 20px 0px #dddddd70;
                }
            }
            ::placeholder {
                color: #9b9b9b;
            }
            .btn {
                position: absolute;
                top: 12px;
                right: 15px;
                color: $black-color;
                font-size: 20px;
                padding: 0;
                transition: $transition;

                &:hover {
                    color: $accent-color;
                }
            }
        }
    }
    .category {
        padding: 25px 30px 25px;
        box-shadow: 0px 0px 20px 0px #dddddd70;

        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: block;
                padding-top: 8px;
                padding-bottom: 8px;
                transition: $transition;

                &:hover {
                    background-color: $accent-color;
                    padding-left: 12px;
                }
                a {
                    display: block;
                    color: $black-color;
                    position: relative;
                    
                    i {
                        display: inline-block;
                        position: absolute;
                        top: 3px;
                        right: 8px;
                        font-size: 20px;
                    }
                }
            }
        }
    }
    .related {
        padding: 25px 30px 25px;
        box-shadow: 0px 0px 20px 0px #dddddd70;

        .related-inner {
            margin-bottom: 12px;

            &:last-child {
                margin-bottom: 0;
            }
            ul {
                margin: 0;
                padding: 0;
                display: flex;
                flex-wrap: wrap;

                li {
                    list-style-type: none;
                    display: block;
                    flex: 0 0 30%;
                    max-width: 30%;

                    &:last-child {
                        flex: 0 0 60%;
                        max-width: 60%;
                        margin-left: 15px;
                    }
                    span {
                        display: block;
                        font-size: 13px;
                        color: #6b6a6a;
                        margin-bottom: 3px;
                    }
                    a {
                        display: block;
                        color: $black-color;
                        font-weight: 500;

                        &:hover {
                            color: $accent-color;
                        }
                    }
                }
            }
        }
    }
    .newsletter {
        padding: 25px 30px 25px;
        box-shadow: 0px 0px 20px 0px #dddddd70;
        text-align: center;

        h3 {
            margin-bottom: 5px;
        }
        span {
            display: block;
            font-weight: 600;
            font-size: 15px;
            color: #656565;
            margin-bottom: 20px;
            font-family: $heading-font;
        }
        .form-control {
            height: 45px;
            background-color: #f8f6f6;
            border: 0;
            box-shadow: none;
            padding-left: 20px;
            font-size: 15px;
            border-radius: 0;
            margin-bottom: 15px;

            &:focus {
                border: 0;
                box-shadow: none;
            }
        }
        ::placeholder {
            color: #919192;
        }
        .subscribe-btn {
            width: 100%;
            display: block;
            border-radius: 0;
            padding-top: 10px;
            padding-bottom: 10px;
            font-size: 15px;
            font-weight: 500;
            background-color: $accent-color;
            transition: $transition;

            &:hover {
                color: $white-color;
                background-color: $black-color;
            }
        }
        .validation-danger {
            margin-top: 10px;
            color: #dc3545;
            font-size: 14px;
        }
    }
    .tags {
        padding: 25px 30px 25px;
        box-shadow: 0px 0px 20px 0px #dddddd70;

        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;
                margin-right: 5px;
                margin-bottom: 8px;

                a {
                    display: block;
                    font-size: 14px;
                    color: #787878;
                    border: 1px dashed #787878;
                    padding: 5px 12px;

                    &:hover {
                        color: $black-color;
                        background-color: $accent-color;
                        border: 1px solid $accent-color;
                    }
                }
            }
        }
    }
    .shop {
        position: relative;
        padding: 25px 30px 25px;
        box-shadow: 0px 0px 20px 0px #dddddd70;

        &:hover {
            .cmn-btn {
                top: 50%;
                opacity: 1;
            }
        }
        .cmn-btn {
            position: absolute;
            left: 50%;
            right: 0;
            top: 53%;
            margin-left: auto;
            margin-right: auto;
            max-width: 125px;
            transform: translateX(-50%) translateY(-50%);
            opacity: 0;
        }
    }
}

/*-- Services Details --*/
.service-details-area {
    .details-item {
        h2 {
            font-weight: 600;
            font-size: 24px;
            color: $black-color;
            margin-bottom: 15px;
        }
        .details-img {
            margin-bottom: 30px;

            img {
                margin-bottom: 25px;
            }
            p {
                margin-bottom: 0;
            }
        }
        .choose {
            margin-bottom: 30px;

            ul {
                margin: 0;
                padding: 0;
                display: flex;
                flex-wrap: wrap;
                max-width: 550px;

                li {
                    list-style-type: none;
                    display: inline-block;
                    flex: 0 0 50%;
                    max-width: 50%;
                    font-weight: 500;
                    font-size: 17px;
                    color: $black-color;
                    margin-bottom: 15px;

                    &:hover {
                        i {
                            color: $white-color;
                            background-color: $black-color;
                        }
                    }
                    i {
                        display: inline-block;
                        color: $black-color;
                        background-color: $accent-color;
                        width: 35px;
                        height: 35px;
                        line-height: 35px;
                        border-radius: 50%;
                        text-align: center;
                        font-size: 20px;
                        margin-right: 5px;
                        transition: $transition;
                    }
                }
            }
            p {
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .clean {
            margin-bottom: 30px;

            img {
                width: 100%;
                margin-bottom: 25px;
            }
            p {
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
/*----- End Service CSS -----*/

/*----- Project CSS -----*/
/*-- Home One --*/
.project-area {
    background-color: $main-color;

    .section-title {
        text-align: left;
        
        .sub-title {
            color: $white-color;
        }
        h2 {
            color: $white-color;
        }
    }
    .owl-theme .owl-nav {
        margin-top: 0;
        position: absolute;
        top: -85px;
        right: 0;
    }
    .owl-prev {
        width: 40px;
        height: 40px;
        text-align: center;
        font-size: 30px !important;
        color: $black-color !important;
        background-color: $accent-color !important;
        border-radius: 50% !important;
        transition: $transition;

        i {
            line-height: 40px;
        }
        &:hover {
            background-color: $white-color !important;
        }
    }  
    .owl-next {
        width: 40px;
        height: 40px;
        text-align: center;
        font-size: 30px !important;
        color: $black-color !important;
        background-color: $accent-color !important;
        border-radius: 50% !important;
        transition: $transition;

        i {
            line-height: 40px;
        }
        &:hover {
            background-color: $white-color !important;
        }
    }    
}
.project-item {
    background-color: $white-color;
    transition: $transition;

    &:hover {
        .project-top {
            img {
                transform: scale(1.1);
            }
        }
        .project-bottom {
            .project-btn {
                right: 5px;
            }
        }
    }
    .project-top {
        overflow: hidden;

        a {
            display: block;

            img {
                width: 100%;
                transition: $transition;
            }
        }
    }
    .project-bottom {
        position: relative;
        padding: 20px 15px 20px;

        .project-btn {
            display: inline-block;
            width: 35px;
            height: 35px;
            border-radius: 5px;
            text-align: center;
            color: $black-color;
            background-color: $accent-color;
            font-size: 20px;
            position: absolute;
            right: 15px;
            top: -18px;
            transition: $transition;

            i {
                line-height: 35px;
            }
            &:hover {
                color: $white-color;
                background-color: $black-color;
            }
        }
        h3 {
            margin-bottom: 5px;
            font-weight: 600;
            font-size: 22px;
            
            a {
                display: block;
                color: $black-color;

                &:hover {
                    color: #fdf288;
                }
            }
        }
        p {
            margin-bottom: 0;
            color: #02063c;
            font-weight: 500;
            font-size: 15px;
        }
    }
}

/*-- Projects --*/
.project-area-two {
    background-color: $white-color;

    .project-item {
        margin-bottom: 30px;
    }
}

/*----- Project Details CSS -----*/
.project-details-area {
    .details-item {
        h3 {
            margin-bottom: 12px;
            color: $black-color;
            font-weight: 600;
            font-size: 24px;
        }
        img {
            width: 100%;
            margin-bottom: 30px;
        }
        p {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }
        .details-img, .details-office {
            margin-bottom: 30px;
        }
    }
}
/*----- End Project CSS -----*/

/*----- Choose CSS -----*/
.choose-item {
    margin-bottom: 30px;
    text-align: center;

    .icon {
        i {
            display: inline-block;
            width: 150px;
            height: 150px;
            line-height: 150px;
            text-align: center;
            border-radius: 50%;
            color: $black-color;
            font-size: 65px;
            margin-bottom: 18px;
            background: radial-gradient(circle, rgba(255,255,255,0) 25%, rgba(171,213,223,1) 100%);
        }
    }
    h3 {
        font-weight: 600;
        font-size: 24px;
        color: $black-color;
        margin-bottom: 10px;
    }
    p {
        margin-bottom: 12px;
    }
    a {
        display: inline-block;
        color: $black-color;
        font-weight: 500;

        i {
            display: inline-block;
            font-size: 17px;
            top: 3px;
            position: relative;
            animation: unset;
        }
        &:hover {
            i {
                animation: service-btn-rotate 1s infinite linear;
            }
        }
    }
}
/*----- End Choose CSS -----*/

/*----- Virtual CSS -----*/
/*-- Home One --*/
.virtual-area {
    background-color: $main-color;
    position: relative;

    .virtual-shape {
        img {
            position: absolute;

            &:nth-child(1) {
                top: 0;
                left: 0;
            }
            &:nth-child(2) {
                bottom: 50px;
                left: 0;
                max-width: 100px;
            }
        }
    }
    .container-fluid {
        padding-left: 0;
        padding-right: 0;

        .col-lg-6 {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .virtual-content {
        padding-top: 100px;
        padding-bottom: 170px;
        padding-right: 30px;
        max-width: 640px;
        margin-left: auto;
        position: relative;
        z-index: 1;

        .section-title {
            text-align: left;
            margin-bottom: 30px;

            h2 {
                color: $white-color;
            }
        }
        p {
            margin-bottom: 12px;
            color: $white-color;
        }
        span {
            display: block;
            font-weight: 500;
            color: $white-color;
            line-height: 28px;
            margin-bottom: 35px;
        }
        ul {
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;

            li {
                list-style-type: none;
                display: inline-block;
                position: relative;
                flex: 0 0 45%;
                max-width: 45%;
                padding-left: 80px;
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }
                i {
                    display: inline-block;
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    color: $main-color;
                    background-color: $accent-color;
                    text-align: center;
                    font-size: 35px;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                h4 {
                    font-weight: 600;
                    font-size: 17px;
                    color: $white-color;
                    margin-bottom: 5px;
                }
                p {
                    margin-bottom: 0;
                    color: $white-color;
                    font-size: 14px;
                }
            }
        }
    }
    .virtual-img {
        background-image: url('../img/home2.jpg');
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        padding-top: 140px;
        padding-left: 50px;

        .virtual-img {
            display: none;
        }
        .counter-item {
            position: relative;
            text-align: center;
            margin-bottom: 100px;

            img {
                position: absolute;
                left: 0;
                top: -25px;
                right: 0;
                margin-left: auto;
                margin-right: auto;
            }
            h3 {
                font-weight: 600;
                font-size: 50px;
                color: $main-color;
                margin-bottom: 0;
            }
            p {
                margin-bottom: 0;
                font-weight: 500;
                font-size: 18px;
                color: #010649;
                position: relative;
                z-index: 1;
            }
        }
    }
}

/*-- Home Three --*/
.virtual-area-two {
    .virtual-content {
        padding-bottom: 100px;
    }
    .virtual-img {
        background-image: url('../img/home-three/virtual-main.jpg');
        position: relative;

        .video-wrap {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);

            a {
                z-index: 10;
                display: block;
                width: 100px;
                height: 100px;
                line-height: 110px;
                border-radius: 50%;
                position: relative;
                font-size: 60px;
                text-align: center;
                color: $black-color;

                &:before {
                    content: "";
                    position: absolute;
                    z-index: 0;
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    display: block;
                    width: 100px;
                    height: 100px;
                    background-color: $accent-color;
                    border-radius: 50%;
                    z-index: -1;
                    animation: pulse-border 1500ms ease-out infinite;
                }
                &:after {
                    content: "";
                    position: absolute;
                    z-index: 1;
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    display: block;
                    width: 100px;
                    height: 100px;
                    background-color: $accent-color;
                    border-radius: 50%;
                    z-index: -1;
                    transition: $transition;
                }
                &:hover {
                    color: $white-color;
    
                    &:before {
                        background-color: $black-color;
                    }
                    &:after {
                        background-color: $black-color;
                    }
                }
            }
        }
    }
}
@keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }
    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
}
/*----- End Virtual CSS -----*/

/*----- Help CSS -----*/
/*-- Home One --*/
.help-area {
    .help-content {
        background-color: $accent-color;
        background-image: url('../img/home-one/help-bg.png');
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        max-width: 1280px;
        border-radius: 15px;
        padding-top: 70px;
        padding-bottom: 70px;
        text-align: center;
        position: relative;
        margin-top: -70px;

        .section-title {
            margin-bottom: 15px;

            h2 {
                max-width: 500px;
                margin-left: auto;
                margin-right: auto;
            }
        }
        .cmn-btn {
            color: $black-color;
            background-color: $white-color;

            &:hover {
                color: $white-color;
            }
        }
    }
}

/*-- Home Two --*/
.help-area-two {
    background-image: url('../img/home-three/help-bg.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: #060823;
        opacity: .80;
    }
    .section-title {
        text-align: left;
        position: relative;

        .sub-title {
            color: $white-color;
        }
        h2 {
            color: $white-color;
            max-width: 500px;
        }
    }
    .help-item {
        max-width: 930px;
        margin-left: auto;
        margin-right: auto;

        .form-group {
            margin-bottom: 30px;
            position: relative;
    
            label {
                margin-bottom: 0;
                position: absolute;
                color: $white-color;
                top: 15px;
                right: 0;
                font-size: 22px;
            }
            .form-control {
                height: 60px;
                padding-left: 0;
                padding-right: 40px;
                border: 0;
                border-bottom: 1px solid #f1f1f170;
                font-size: 15px;
                border-radius: 0;
                background-color: transparent;
                color: $white-color;
    
                &:focus {
                    box-shadow: none;
                    border: 0;
                    border-bottom: 1px solid #f1f1f170;
                }
            }
            ::placeholder {
                color: $white-color;
            }
            textarea {
                height: auto !important;
            }
            .nice-select {
                border: 0;
                border-bottom: 1px solid #f1f1f170;
                background-color: transparent;
                color: $white-color;
                border-radius: 0;
                position: relative;
                z-index: 1;
                display: block;
                width: 100%;
                margin-bottom: 30px;
                padding-left: 0;
    
                &:after {
                    border-bottom: 2px solid #fff;
                    border-right: 2px solid #fff;
                    height: 7px;
                    width: 7px;
                }
                .list {
                    width: 100%;
                    display: block;

                    li {
                        color: $black-color;
                    }
                }
            }
        }
        .cmn-btn {
            border: 0;
        }
    }
}
/*----- End Help CSS -----*/

/*----- Testimonial CSS -----*/
/*-- Home One --*/
.testimonial-area {
    .testimonial-item {
        background-color: #161428;
        border-radius: 5px;
        padding: 40px 65px 40px 20px;
        position: relative;

        .quote {
            position: absolute;
            right: 25px;
            bottom: 5px;
            color: #b1a544;
            font-size: 50px;
        }
        ul {
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;

            li {
                list-style-type: none;
                display: inline;
                flex: 0 0 20%;
                max-width: 20%;
                margin-right: 15px;

                &:last-child {
                    flex: 0 0 70%;
                    max-width: 70%;
                    margin-right: 0;
                }
                .test-left {
                    text-align: center;

                    img {
                        margin-bottom: 15px;
                        border-radius: 5px;
                        width: 75px;
                        margin-left: auto;
                        margin-right: auto;
                    }
                    h4 {
                         font-family: $common-font;
                         font-weight: 500;
                         font-size: 16px;
                         color: $white-color;
                         margin-bottom: 5px;
                    }
                    span {
                        display: block;
                        color: $accent-color;
                        font-size: 12px;
                    }
                }
                .test-right {
                    p {
                        color: $white-color;
                        margin-bottom: 12px;
                    }
                    span {
                        display: inline-block;
                        font-size: 20px;

                        .checked {
                            color: #ffa500;
                        }
                    }
                }
            }
        }
    }
}

/*-- Home One --*/
.testimonial-area-two {
    .testimonial-item {
        ul {
            li {
                .test-right {
                    p {
                        font-style: italic;
                    }
                }
            }
        }
    }
}

/*-- Home Three --*/
.testimonial-area-three {
    background-image: url('../img/home-three/testimonial-bg.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    .testimonial-item {
        ul {
            li {
                .test-right {
                    p {
                        font-style: italic;
                    }
                }
            }
        }
    }
}
/*----- End Testimonial CSS -----*/

/*----- Blog CSS -----*/
/*-- Home One --*/
.blog-item {
    background-color: $white-color;
    box-shadow: 0px 0px 20px 0px #dddddd70;
    margin-bottom: 30px;

    &:hover {
        .blog-top {
            a {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
    .blog-top {
        position: relative;
        overflow: hidden;

        a {
            display: block;

            img {
                width: 100%;
                transition: $transition;
            }
        }
        span {
            display: inline-block;
            font-size: 13px;
            color: $black-color;
            background-color: $accent-color;
            padding: 10px 15px;
            position: absolute;
            font-weight: 500;
            top: 0;
            left: 0;
        }
    }
    .blog-bottom {
        padding: 22px 20px 25px;

        h3 {
            margin-bottom: 15px;
            font-weight: 600;
            font-size: 22px;
            line-height: 34px;

            a {
                color: $black-color;

                &:hover {
                    color: $accent-color;
                }
            }
        }
    }
}

/*-- Blog Details --*/
.blog-details-area {
    .details-item {
        h2 {
            font-weight: 600;
            font-size: 24px;
            color: $black-color;
            margin-bottom: 15px;
        }
        .details-img {
            margin-bottom: 30px;

            img {
                margin-bottom: 25px;
            }
            ul {
                margin: 0;
                padding: 0;
                margin-bottom: 15px;

                li {
                    list-style-type: none;
                    display: inline-block;
                    margin-right: 12px;

                    &:last-child {
                        margin-right: 0;
                    }
                    span {
                        display: inline-block;
                        font-size: 12px;
                        color: #777676;
                    }
                    a {
                        display: inline-block;
                        color: #777676;
                        font-weight: 500;
                        font-size: 13px;

                        &:hover {
                            color: $black-color;
                        }
                    }
                }
            }
        }
        .details-tag {
            margin-bottom: 30px;

            span {
                display: inline-block;
                font-weight: 500;
                font-size: 14px;
                color: #9b9b9b;
                margin-right: 10px;
            }
            ul {
                display: inline-block;
                margin: 0;
                padding: 0;

                li {
                    list-style-type: none;
                    display: inline-block;
                    margin-right: 8px;

                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        display: block;
                        color: #504f4f;
                        font-size: 15px;
                        background-color: #f4f0f0;
                        padding: 10px 15px;

                        &:hover {
                            color: $black-color;
                            background-color: $accent-color;
                        }
                    }
                }
            }
        }
    }
    .details-quote {
        margin-bottom: 30px;

        p {
            margin-bottom: 25px;

            &:last-child {
                margin-bottom: 0;
            }
        }
        blockquote {
            background-color: #f4f4f4;
            border-left: 3px solid $accent-color;
            padding: 45px 25px 45px 65px;
            font-weight: 500;
            font-size: 20px;
            color: $black-color;
            font-style: italic;
            position: relative;
            margin-bottom: 25px;

            i {
                position: absolute;
                top: 30px;
                left: 25px;
                color: $black-color;
                font-size: 35px;
            }
        }
        .img {
            margin-bottom: 30px;
            position: relative;

            span {
                display: inline-block;
                color: $black-color;
                background-color: $white-color;
                font-weight: 500;
                font-size: 14px;
                position: absolute;
                top: 0;
                left: 0;
                padding: 10px 23px;
                max-width: 70px;
                text-align: center;
            }
            img {
                width: 100%;
            }
        }
    }
    .details-comment {
        margin-bottom: 30px;

        h3 {
            font-weight: 500;
            font-size: 18px;
            color: $black-color;
            display: inline-block;
            border-bottom: 1px solid #f1f1f1;
            margin-bottom: 35px;
        }
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: block;
                position: relative;
                padding-left: 100px;
                border-bottom: 1px solid #e3e3e3;
                padding-bottom: 40px;
                margin-bottom: 40px;

                &:nth-child(2) {
                    margin-left: 70px;
                }
                &:nth-child(3) {
                    border-bottom: 0;
                    padding-bottom: 0;
                    margin-bottom: 0;
                }
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                h4 {
                    font-size: 16px;
                    color: $black-color;
                    font-weight: 500;
                    margin-bottom: 7px;
                }
                span {
                    display: block;
                    color: #9b9b9b;
                    font-size: 14px;
                    margin-bottom: 12px;
                }
                p {
                    margin-bottom: 0;
                }
                a {
                    display: inline-block;
                    color: #9b9b9b;
                    font-size: 14px;
                    position: absolute;
                    top: 0;
                    right: 0;

                    &:hover {
                        color: $accent-color;
                    }
                }
            }
        }
    }
    .details-form {
        background-color: #f4f4f4;
        padding: 35px 45px 35px;
        margin-bottom: 30px;

        h3 {
            color: $black-color;
            font-weight: 500;
            font-size: 18px;
            margin-bottom: 20px;
        }
        .form-group {
            margin-bottom: 30px;

            .form-control {
                height: 45px;
                border: 0;
                background-color: $white-color;
                border-radius: 0;
                padding-left: 20px;
                font-size: 15px;
                font-weight: 500;

                &:focus {
                    border: 0;
                    box-shadow: none;
                }
            }
            ::placeholder {
                color: #9b9b9b;
            }
            textarea {
                padding-top: 10px;
                height: auto !important;
            }
        }
        .cmn-btn {
            border: 0;
        }
    }
}
/*----- End Blog CSS -----*/

/*----- Logo CSS -----*/
.logo-area {
    background-color: #fffef5;

    .col-lg-5 {
        flex: 0 0 20%;
        max-width: 20%;
    }
}
.logo-item {
    text-align: center;
    margin-bottom: 30px;
    position: relative;

    &:hover {
        img {
            &:nth-child(2) {
                opacity: 0;
                top: -50px;
            }
        }
    }
    img {
        &:nth-child(1) {
            position: relative;
        }
        &:nth-child(2) {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            margin-left: auto;
            margin-right: auto;
            transition: $transition;
        }
    }
}
/*----- End Logo CSS -----*/

/*----- Footer CSS -----*/
footer {
    background-color: #060823;
    background-image: url('../img/home-one/footer-bg.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.footer-item {
    margin-bottom: 30px;

    .footer-logo {
        .logo {
            display: block;
            margin-bottom: 25px;
        }
        p {
            color: $white-color;
            margin-bottom: 22px;
        }
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;
                margin-right: 5px;

                &:nth-child(2) {
                    a {
                        color: #1da1f2;

                        &:hover {
                            background-color: #1da1f2;
                        }
                    }
                }
                &:nth-child(3) {
                    a {
                        color: #d43131;

                        &:hover {
                            background-color: #d43131;
                        }
                    }
                }
                &:nth-child(4) {
                    margin-right: 0;

                    a {
                        color: #0077b5;

                        &:hover {
                            background-color: #0077b5;
                        }
                    }
                }
                a {
                    display: block;
                    width: 35px;
                    height: 35px;
                    text-align: center;
                    color: #3955bc;
                    border-radius: 50%;
                    font-size: 18px;
                    background-color: $white-color;

                    i {
                        line-height: 35px;
                    }
                    &:hover {
                        color: $white-color;
                        background-color: #3955bc;
                    }
                }
            }
        }
    }
    .footer-head {
        h3 {
            font-weight: 600;
            font-size: 24px;        
            color: $accent-color;
            position: relative;
            padding-bottom: 15px;
            margin-bottom: 30px;        

            &:before {
                position: absolute;
                content: '';
                bottom: 0;
                left: 0;
                height: 2px;
                width: 50px;
                background-color: $accent-color;
            }
        }
    }
    .footer-service {
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: block;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    display: block;
                    color: $white-color;

                    i {
                        display: inline-block;
                        font-size: 22px;
                        position: relative;
                        top: 3px;
                        margin-right: 2px;
                    }
                    &:hover {
                        letter-spacing: 1px;
                        color: $accent-color;
                    }
                }
            }
        }
    }
    .footer-info {
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: block;
                position: relative;
                padding-left: 45px;
                margin-bottom: 25px;

                &:last-child {
                    margin-bottom: 0;
                }
                i {
                    display: inline-block;
                    color: $accent-color;
                    font-size: 30px;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                h4 {
                    font-weight: 600;
                    font-size: 18px;
                    color: $white-color;
                    margin-bottom: 5px;
                }
                a {
                    display: block;
                    color: $white-color;
                    font-size: 15px;

                    &:hover {
                        letter-spacing: 1px;
                        color: $accent-color;
                    }
                }
                span {
                    display: block;
                    color: $white-color;
                    font-size: 15px;
                }
            }
        }
    }
}
/*----- End Footer CSS -----*/

/*----- Copyright CSS -----*/
.copyright-area {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #012b6b;

    .copyright-item {
        p {
            margin-bottom: 0;
            font-size: 15px;
            color: $white-color;

            a {
                display: inline-block;
                color: $accent-color;
                font-weight: 600;

                &:hover {
                    color: $white-color;
                }
            }
        }
    }
}
/*----- End Copyright CSS -----*/

/*----- Preloader -----*/
.loader {
    position: fixed;
    z-index: 99999;
    background: $black-color;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}
.spinner {
    margin: 100px auto;
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px;
}
.spinner > div {
    background-color: $white-color;
    height: 100%;
    width: 6px;
    display: inline-block;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
}
.spinner .rect2 {
    animation-delay: -1.1s;
}
.spinner .rect3 {
    animation-delay: -1.0s;
}
.spinner .rect4 {
    animation-delay: -0.9s;
}
.spinner .rect5 {
    animation-delay: -0.8s;
}
@keyframes sk-stretchdelay {
    0%, 40%, 100% { 
        transform: scaleY(0.4);
    }  
    20% { 
        transform: scaleY(1.0);
    }
}
/*----- End Preloader -----*/

/*----- Back to Top -----*/
#toTop {
	position: fixed;
	bottom: 30px;
    right: 0;
	cursor: pointer;
    display: none;
    z-index: 10;
}
.back-to-top-btn {
    i {
        background: $accent-color;
        color: $black-color;
        height: 50px;
        width: 50px;
        line-height: 50px;
        display: inline-block;
        text-align: center;
        font-size: 28px;
        border-radius: 50%;
        transition: $transition;
        margin-right: 28px;

        &:hover {
            background-color: $black-color;
            color: $white-color;
        }
    }
}
/*----- End Back to Top -----*/

/*----- Team CSS -----*/
.team-item {
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;

    &:hover {
        .team-bottom {
            ul {
                visibility: visible;

                li {
                    &:nth-child(1) {
                        top: 0;
                        opacity: 1;
                    }
                    &:nth-child(2) {
                        top: 0;
                        opacity: 1;
                    }
                    &:nth-child(3) {
                        top: 0;
                        opacity: 1;
                    }
                    &:nth-child(4) {
                        top: 0;
                        opacity: 1;
                    }
                }
            }
        }
    }
    &:before {
        position: absolute;
        content: "";
        bottom: 0;
        right: 0;
        left: 0;
        border-left: 425px solid transparent;
        border-bottom: 125px solid #fdf288;
        width: 100%;
    }
    .team-bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding-right: 8px;
        padding-bottom: 10px;

        ul {
            margin: 0;
            padding: 0;
            text-align: center;
            top: -10px;
            left: -45px;
            position: relative;
            visibility: hidden;

            li {
                list-style-type: none;
                display: inline-block;
                margin-right: 3px;
                transition: $transition;

                &:nth-child(1) {
                    top: -10px;
                    position: relative;
                    opacity: 0;
                }
                &:nth-child(2) {
                    top: 10px;
                    position: relative;
                    opacity: 0;
                }
                &:nth-child(3) {
                    top: -10px;
                    position: relative;
                    opacity: 0;
                }
                &:nth-child(4) {
                    top: 10px;
                    position: relative;
                    margin-right: 0;
                    opacity: 0;
                }
                a {
                    display: block;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    font-size: 17px;
                    text-align: center;
                    color: $black-color;
                    background-color: $accent-color;

                    i {
                        line-height: 30px;
                    }
                    &:hover {
                        color: $white-color;
                        background-color: $black-color;
                    }
                }
            }
        }
        .team-content {
            max-width: 170px;
            margin-left: auto;

            h3 {
                font-weight: 600;
                font-size: 20px;
                color: $black-color;
                margin-bottom: 5px;
                text-align: center;
            }
            span {
                display: block;
                color: #8f9090;
                font-size: 14px;
                text-align: center;
            }
        }
    }
}
/*----- End Team CSS -----*/

/*----- Shop CSS -----*/
/*-- Home Two --*/
.shop-item {
    margin-bottom: 30px;
    background-color: $white-color;
    box-shadow: 0px 0px 20px 0px #dddddd70;
    text-align: center;

    &:hover {
        .shop-top {
            img {
                transform: scale(1.1);
            }
            .cmn-btn {
                bottom: 25px;
                opacity: 1;
                visibility: visible;
            }
        }
    }
    .shop-top {
        position: relative;
        overflow: hidden;

        img {
            width: 100%;
            transition: $transition;
        }
        .cmn-btn {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            margin-left: auto;
            margin-right: auto;
            max-width: 140px;
            opacity: 0;
            visibility: hidden;
        }
    }
    .shop-bottom {
        padding: 30px 20px 30px;

        h3 {
            font-weight: 600;
            font-size: 24px;
            margin-bottom: 5px;

            a {
                color: $black-color;

                &:hover {
                    color: $accent-color;
                }
            }
        }
        span {
            display: block;
            color: $main-color;
            font-size: 17px;
        }
    }
}

/*-- Shop --*/
.shop-item-two {
    margin-bottom: 30px;
    text-align: center;

    &:hover {
        .shop-top {
            img {
                transform: scale(1.1);
            }
            .cmn-btn {
                bottom: 35px;
                opacity: 1;
            }
            ul {
                li {
                    &:nth-child(1) {
                        left: 0;
                        opacity: 1;
                    }
                    &:nth-child(2) {
                        right: 0;
                        opacity: 1;
                    }
                    &:nth-child(3) {
                        left: 0;
                        opacity: 1;
                    }
                    &:nth-child(4) {
                        right: 0;
                        opacity: 1;
                    }
                }
            }
        }
    }
    .shop-top {
        position: relative;
        overflow: hidden;
        margin-bottom: 20px;
        overflow: hidden;

        img {
            width: 100%;
            transition: $transition;
        }
        .cmn-btn {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            margin-left: auto;
            margin-right: auto;
            max-width: 135px;
            opacity: 0;
        }
        ul {
            margin: 0;
            padding: 0;
            position: absolute;
            bottom: 145px;
            right: 45px;

            li {
                list-style-type: none;
                display: block;
                margin-bottom: 10px;
                position: relative;
                transition: $transition;

                &:nth-child(1) {
                    left: 15px;
                    opacity: 0;
                }
                &:nth-child(2) {
                    right: 15px;
                    opacity: 0;
                }
                &:nth-child(3) {
                    left: 15px;
                    opacity: 0;
                }
                &:nth-child(4) {
                    right: 15px;
                    opacity: 0;
                    margin-bottom: 0;
                }
                a {
                    display: block;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    color: #8e8e8d;
                    background-color: $white-color;
                    font-size: 16px;

                    i {
                        line-height: 30px;
                    }
                    &:hover {
                        color: $black-color;
                        background-color: $accent-color;
                    }
                }
            }
        }
    }
    .shop-bottom {
        h3 {
            font-weight: 600;
            font-size: 24px;
            color: $black-color;
            margin-bottom: 8px;
        }
        span {
            display: block;
            font-weight: 500;
            font-size: 18px;
            color: #fc2a33;
            margin-bottom: 7px;
        }
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;
                font-size: 20px;

                i {
                    display: inline-block;
                }
                .checked {
                    color: #ffc107;
                }
            }
        }
    }
}

/*-- Product Details --*/
.product-details-area {
    .details-item {
        .details-hand {
            margin-bottom: 30px;

            h3 {
                font-weight: 600;
                font-size: 20px;
                color: $black-color;
                margin-bottom: 10px;
            }       
            .review {
                margin: 0;
                padding: 0;
                margin-bottom: 15px;

                li {
                    list-style-type: none;
                    display: inline-block;

                    i {
                        display: inline-block;
                        font-size: 18px;
                    }
                    .checked {
                        color: #ffc107;
                    }
                }
            }   
            .text {
                margin: 0;
                padding: 0;

                li {
                    list-style-type: none;
                    display: block;
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }  
            .number {
                margin: 0;
                padding: 0;
                margin-top: 20px;
                margin-bottom: 20px;

                li {
                    list-style-type: none;
                    display: inline-block;

                    .minus, .plus{
                        width: 40px;
                        height: 45px;
                        line-height: 45px;
                        color: $grey-color;
                        display: inline-block;
                        text-align: center;
                        cursor: pointer;
                        margin-bottom: 0;
                        vertical-align: middle;
                        transition: $transition;
    
                        &:hover {
                            color: $accent-color;
                        }
                    }
                    input{
                        height: 45px;
                        width: 50px;
                        text-align: center;
                        font-size: 28px;
                        border: 0;
                        font-size: 14px;
                        color: $grey-color;
                        display: inline-block;
                        vertical-align: middle;
                        margin-left: -4px;
                        margin-right: -4px;
                    }
                    &:first-child {
                        border: 1px dashed $grey-color;
                    }
                    &:last-child {
                        margin-left: 15px;

                        a {
                            display: inline-block;
                            font-weight: 500;
                            padding: 12px 25px;
                            color: $black-color;
                            background-color: $accent-color;

                            &:hover {
                                color: $white-color;
                                background-color: $black-color;
                            }
                        }
                    }
                }
            }
            h4 {
                margin-bottom: 10px;
                color: $black-color;
                font-weight: 600;
                font-size: 18px;
            }
            .cat {
                margin: 0;
                padding: 0;

                li {
                    list-style-type: none;
                    display: block;
                    font-size: 15px;
                    margin-bottom: 5px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .details-tabs {
            margin-bottom: 50px;

            .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
                color: $accent-color;
                background-color: #fff;
            }
            ul {
                margin-bottom: 25px;

                li {
                    margin-right: 35px;

                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        color: $black-color;
                        font-size: 20px;
                        font-weight: 500;
                        padding: 0;
                    }
                }
            }
            .table-bordered td, .table-bordered th {
                border: 1px solid #dee2e6;
                text-align: center;
                font-weight: 400;
                font-size: 15px;
            }
            .review {
                margin: 0;
                padding: 0;

                li {
                    list-style-type: none;
                    display: block;
                    position: relative;
                    padding-left: 90px;
                    margin-bottom: 40px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                    h3 {
                        margin-bottom: 8px;
                        font-weight: 600;
                        font-size: 20px;
                        color: $black-color;
                    }
                    span {
                        display: inline-block;
                        font-size: 17px;
                        margin-right: 3px;
    
                        i {
                            display: inline-block;
                        }
                        .checked {
                            color: #ffc107;
                        }
                    }
                    p {
                        margin-bottom: 0;
                        margin-top: 10px;
                        max-width: 620px;
                    }
                }
            }
        }
    }
}
/*----- End Shop CSS -----*/

/*----- Support CSS -----*/
.support-area {
    margin-top: -70px;
    position: relative;
    z-index: 1;

    .support-wrap {
        background-color: #f5f5fe;
        border-radius: 8px;
        background-image: url('../img/home-two/support-bg.png');
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        padding: 30px;

        .support-content {
            background-color: $white-color;
            max-width: 500px;
            padding: 30px 25px 30px;

            .section-title {
                text-align: left;
                margin-bottom: 20px;

                .sub-title {
                    font-size: 14px;
                }
                h2 {
                    font-size: 24px;
                }
            }
            .form-group {
                margin-bottom: 20px;
                position: relative;

                label {
                    margin-bottom: 0;
                    position: absolute;
                    right: 15px;
                    top: 12px;
                    font-size: 22px;

                    i {
                        display: inline-block;
                    }
                }
                .form-control {
                    height: 50px;
                    border-radius: 5px;
                    padding-left: 15px;
                    padding-right: 40px;
                    background-color: #f6f6f6;
                    font-size: 14px;
                    border: 0;

                    &:focus {
                        box-shadow: none;
                        border: 0;
                    }
                }
                ::placeholder {
                    color: #8e8e8e;
                }
                textarea {
                    height: auto !important;
                    padding-top: 10px;
                }
                .nice-select {
                    display: block;
                    width: 100%;
                    margin-bottom: 20px;
                    border: 0;
                    background-color: #f6f6f6;
                    position: relative;
                    z-index: 1;
                    color: #8e8e8e;

                    &.nice-select:after {
                        height: 8px;
                        right: 22px;
                        width: 8px;
                    }
                    .list {
                        display: block;
                        width: 100%;
                    }
                }
            }
            .cmn-btn {
                border: 0;
            }
        }
    }
}
/*----- End Support CSS -----*/

/*----- Price CSS -----*/
.price-area {
    position: relative;

    .price-shape {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;

        &:before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: $main-color;
            opacity: .80;
        }
    }
    .section-title {
        position: relative;

        .sub-title {
            color: $white-color;
        }
        h2 {
            color: $white-color;
        }
    }
}
.price-item {
    margin-bottom: 30px;
    text-align: center;
    background-color: $white-color;
    box-shadow: 0px 0px 20px 0px #dddddd70;
    padding-bottom: 30px;
    transition: $transition;

    &:hover {
        background-color: $main-color;
        transform: translate(0, -10px);

        .price-top {
            p {
                color: $white-color;
            }
            h3 {
                color: $white-color;
            }
            .one {
                color: $white-color;
            }
        }
        .price-bottom {
            ul {
                li {
                    color: $white-color;
                }
            }
            .cmn-btn {
                color: $main-color;
                background-color: $white-color;

                &:hover {
                    color: $white-color;
                }
            }
        }
    }
    .price-img {
        background-color: #e3e4fd;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 25px;
    }
    .price-top {
        margin-bottom: 20px;

        p {
            font-weight: 500;
            color: $black-color;
            margin-bottom: 5px;
        }
        h3 {
            font-weight: 700;
            font-size: 28px;
            color: #0c0c39;
            margin-bottom: 8px;

            span {
                display: inline-block;
                font-size: 15px;
                position: relative;
                right: 5px;
            }
        }
        .one {
            color: #5d5d5d;
            font-size: 14px;
            display: block;
        }
    }
    .price-bottom {
        ul {
            margin: 0;
            padding: 0;
            margin-bottom: 25px;
            text-align: left;
            margin-left: auto;
            margin-right: auto;
            max-width: 180px;

            li {
                list-style-type: none;
                display: block;
                color: #0c0c39;
                margin-bottom: 12px;

                &:last-child {
                    margin-bottom: 0;
                }
                i {
                    display: inline-block;
                    font-size: 20px;
                    position: relative;
                    top: 2px;
                }
            }
        }
    }
}
/*----- End Price CSS -----*/

/*----- Page Title CSS -----*/
.page-title-area {
    background-image: url('../img/page-title-bg.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    height: 400px;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $main-color;
        opacity: .80;
    }
    .page-item {
        position: relative;
        text-align: center;
        margin-top: 40px;

        h2 {
            font-weight: 700;
            font-size: 24px;
            color: $white-color;
            margin-bottom: 8px;
        }
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;
                font-size: 15px;
                color: #adacac;
                font-weight: 500;

                span {
                    display: inline-block;
                    margin-left: 3px;
                    margin-right: 3px;
                }
                a {
                    color: #adacac;
                    display: inline-block;

                    &:hover {
                        color: $accent-color;
                    }
                }
            }
        }
    }
}
/*----- End Page Title CSS -----*/

/*----- Contact CSS -----*/
.contact-area {
    #map {
        display: block;
        border: 0;
        width: 100%;
        height: 550px;
        margin-bottom: 30px;
    }
    .contact-item {
        margin-bottom: 30px;

        .section-title {
            text-align: left;
        }
        .form-group {
            margin-bottom: 30px;
            
            .form-control {
                height: 50px;
                border-radius: 0;
                border: 0;
                padding-left: 20px;
                border: 1px solid #f1f1f1;
                font-size: 15px;

                &:focus {
                    box-shadow: none;
                    border: 1px solid #f1f1f1;
                }
            }
            textarea {
                height: auto !important;
                padding-top: 10px;
            }
            .list-unstyled {
                margin-bottom: 0;
                font-size: 14px;
                color: #dc3545;
                margin-top: 10px;
            }
        }
        .cmn-btn {
            opacity: 1;
            border: 0;
        }
        #msgSubmit {
            margin-top: 10px;
            margin-bottom: 0;
            font-size: 20px;
        }
    }
}
/*----- End Contact CSS -----*/

/*----- Cart CSS -----*/
.cart-area {
    text-align: center;

    .table-item {
        .table {
            margin-bottom: 30px;
        }
        .table-bordered {
            border: 1px solid #f1f1f1;
        }
        .table thead th {
            border-bottom: 1px solid #f1f1f1;
            color: $black-color;
        }
        .table-bordered td, .table-bordered th {
            border: 1px solid #f1f1f1;
            vertical-align: middle;
            font-family: $heading-font;
        }
        img {
            border-radius: 5px;
        }
        .form-control {
            border: 1px solid #f1f1f1;
            max-width: 80px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            border-radius: 5px;

            &:focus {
                box-shadow: none;
                border: 1px solid #f1f1f1;
            }
        }
        a {
            display: inline-block;
            color: $black-color;
            font-size: 25px;

            &:hover {
                color: $accent-color;
            }
        }
    }
    .coupon {
        position: relative;
        margin-bottom: 30px;

        .form-control {
            max-width: 200px;
            height: 50px;
            border-radius: 5px;
            padding-left: 15px;
            font-size: 15px;
            border: 1px solid #f1f1f1;

            &:focus {
                border: 1px solid #f1f1f1;
                box-shadow: none;
            }
        }
        .cmn-btn {
            border: 0;
            position: absolute;
            top: 2px;
            left: 225px;
        }
    }
    .total {
        text-align: left;

        h3 {
            font-weight: 600;
            margin-bottom: 20px;
            font-size: 20px;
            color: $black-color;
            display: inline-block;
            border-bottom: 1px solid #f1f1f1;
        }
        span {
            font-size: 15px;
            margin-right: 10px;
        }
        h4 {
            display: inline-block;
            font-weight: 600;
            font-size: 15px;
            color: $black-color;
        }
        .cmn-btn {
            margin-top: 25px;
        }
    }
}
/*----- End Cart CSS -----*/

/*----- Checkout CSS -----*/
.checkout-area {
    .checkout-head {
        text-align: center;
        background-color: $accent-color;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-bottom: 30px;

        h2 {
            margin-bottom: 0;
            font-weight: 600;
            font-size: 18px;
            color: $black-color;

            a {
                display: inline-block;
                color: $black-color;

                &:hover {
                    letter-spacing: 1px;
                }
            }
        }
    }
    .checkout-item {
        margin-bottom: 30px;

        h3 {
            font-weight: 600;
            font-size: 24px;
            color: $black-color;
            margin-bottom: 30px;
            position: relative;
            border-bottom: 1px solid #f1f1f1;
            padding-bottom: 15px;

            &:before {
                position: absolute;
                content: '';
                width: 50px;
                height: 1px;
                left: 0;
                bottom: -1px;
                background-color: $accent-color;
            }
        }
        .form-group {
            margin-bottom: 30px;

            label {
                margin-bottom: 10px;
                display: block;
                font-size: 15px;
            }
            .form-control {
                height: 45px;
                border-radius: 5px;
                border: 1px solid #f1f1f1;
                padding-left: 15px;

                &:focus {
                    box-shadow: none;
                    border: 1px solid #f1f1f1;
                }
            }
            .nice-select {
                margin-bottom: 30px;
                display: block;
                width: 100%;
                border: 1px solid #f1f1f1;
                height: 45px;

                .list {
                    width: 100%;
                    display: block;
                }
            }
        }
        .table {
            margin-bottom: 30px;
        }
        .table-bordered thead td, .table-bordered thead th {
            padding-top: 15px;
            padding-bottom: 15px;
            vertical-align: middle;
            font-weight: 500;
            font-size: 18px;
            border: 1px solid #f1f1f1;
        }        
        .table-bordered td, .table-bordered th {
            border: 1px solid #f1f1f1;
            font-weight: 400;
            padding-top: 15px;
            padding-bottom: 15px;
        }
        h4 {
            color: $black-color;
            font-weight: 600;
            font-size: 20px;
            padding-left: 25px;
            position: relative;
            margin-bottom: 15px;

            &:before {
                position: absolute;
                content: '';
                width: 12px;
                height: 12px;
                left: 0;
                top: 5px;
                background-color: $accent-color;
                transform: rotate(45deg);
            }
        }
        p {
            margin-bottom: 20px;
        }
        .cmn-btn {
            width: 100%;
            border-radius: 5px;
            text-align: center;
            text-transform: uppercase;
            font-weight: 500;
        }
    }
}
/*----- End Checkout CSS -----*/

/*----- Sign Up CSS -----*/
.form-area {
    .form-item {
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

        h2 {
            margin-bottom: 10px;
            color: $black-color;
            font-weight: 700;
            font-size: 35px;
            position: relative;
            display: inline-block;

            &:before {
                position: absolute;
                content: '';
                width: 10px;
                height: 10px;
                left: -25px;
                top: 18px;
                transform: rotate(45deg);
                background-color: $accent-color;
            }
            &:after {
                position: absolute;
                content: '';
                width: 10px;
                height: 10px;
                right: -25px;
                top: 18px;
                transform: rotate(45deg);
                background-color: $accent-color;
            }
        }
        span {
            display: block;
            margin-bottom: 40px;
        }
        form {
            margin-top: 40px;
        }
        .form-group {
            text-align: left;
            margin-bottom: 30px;

            label {
                margin-bottom: 8px;
                display: block;
            }
            .form-control {
                height: 50px;
                padding-left: 20px;
                border-radius: 0;
                border: 0;
                background-color: #f1f1f1;
                font-size: 15px;

                &:focus {
                    box-shadow: none;
                    border: 0;
                }
            }
        }
        .cmn-btn {
            width: 100%;
            display: block;
            border: 0;
            border-radius: 0;
            margin-bottom: 20px;
        }
        span {
            display: block;
            font-size: 15px;
            margin-bottom: 0 !important;

            a {
                display: inline-block;
                color: $black-color;
                font-weight: 500;

                &:hover {
                    color: #ffe700;
                }
            }
        }
    }
}
/*----- End Sign Up CSS -----*/

/*----- Gallery CSS -----*/
.gallery-area {
    a {
        display: block;
        margin-bottom: 30px;
        position: relative;

        &:before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: $black-color;
            opacity: 0;
            transition: $transition;
        }
        &:hover {
            &:before {
                opacity: .40;
            }
            i {
                opacity: 1;
            }
        }
        i {
            position: absolute;
            left: 50%;
            top: 50%;
            font-size: 65px;
            color: $white-color;
            transform: translateX(-50%) translateY(-50%);
            opacity: 0;
            transition: $transition;
        }
    }
}
/*----- End Gallery CSS -----*/

/*----- FAQ CSS -----*/
.faq-area {
    .accordion { 
        margin: 0;
        padding: 0;
        margin-bottom: 30px;
    
        p {
            display: none;
            padding: 20px 15px 0 15px;
            margin-bottom: 0;
        }
        a {
            width: 100%;
            display: block;
            cursor: pointer;
            padding: 15px 15px 15px 18px;
            border: 1px solid #f5f5f5;

            &:after {
                position: absolute;
                right: 20px;
                content: "+";
                top: 10px;
                color: #000000;
                font-size: 25px;
                font-weight: 500;
            }
            &.active {
                color: $white-color;
                background-color: #000000;
                border: 1px solid #000000;
            }
            &.active:after {
                content: "-";
                font-size: 25px;
                color: $white-color;
            }
        }
        li {
            position: relative;
            list-style-type: none;
            margin-bottom: 30px;
            display: block;
    
            &:first-child {
                border-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .question-side {
        margin-bottom: 30px;

        h3 {
            margin-bottom: 20px;
            font-weight: 600;
            color: $black-color;
            font-size: 24px;
            position: relative;
            padding-bottom: 10px;
            border-bottom: 1px solid #f5f5f5;

            &:before {
                position: absolute;
                content: '';
                width: 50px;
                height: 1px;
                left: 0;
                bottom: -1px;
                background-color: $black-color;
            }
        }
        form {
            .form-group {
                margin-bottom: 15px;

                .form-control {
                    height: 50px;
                    background-color: #f5f5f5;
                    border: 0;
                    border-radius: 0;
                    padding-left: 20px;
                    font-size: 15px;

                    &:focus {
                        box-shadow: none;
                        border: 0;
                    }
                }
                textarea {
                    height: auto !important;
                    padding-top: 10px;
                }   
            }
            .cmn-btn {
                border: 0;
                border-radius: 0;
                width: 100%;
                display: block;
            }
        }
    }
}
/*----- End FAQ CSS -----*/

/*----- Error CSS -----*/
.error-item {
    height: 600px;
    text-align: center;

    h1 {
        font-size: 130px;
        font-weight: 700;
        margin-bottom: 8px;
        color: $black-color;
    }
    p {
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 35px;
        color: $black-color;

        i {
            display: inline-block;
        }
    }
    span {
        display: block;
        color: #646464;
    }
    a {
        display: inline-block;
        color: $white-color;
        background-color: $black-color;
        padding: 20px 45px;
        margin-top: 70px;
        font-size: 18px;

        &:hover {
            background-color: $accent-color;
        }
    }
}
/*----- End Error CSS -----*/

/*----- Coming Soon CSS -----*/
.coming-item {
    height: 100vh;
    text-align: center;

    h1 {
        font-size: 75px;
        font-weight: 700;
        margin-bottom: 40px;
        color: $black-color;
    }
    .coming-wrap {
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;

        .coming-inner{
            text-align: center;
            border: 1px solid #f5f5f5;
            padding-top: 15px;
            padding-bottom: 12px;
            margin-bottom: 30px;
        
            h3{
                font-size: 40px;
                font-weight: 600;
                color: $black-color;
                margin-bottom: 0;
            }
            p{
                font-size: 16px;
                margin-bottom: 0;
            }
        }
    }
    ul {
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
            display: inline-block;
            margin-right: 2px;
            margin-left: 2px;

            a {
                display: block;
                color: $white-color;
                background-color: $black-color;
                width: 35px;
                height: 35px;
                line-height: 39px;
                border-radius: 50%;
                font-size: 16px;

                &:hover {
                    background-color: $accent-color;
                }
            }
        }
    }
}
/*----- End Coming Soon CSS -----*/

/*----- Privacy Policy CSS -----*/
.privacy-area {
    padding-bottom: 50px;
}
.privacy-item {
    margin-bottom: 50px;

    h2 {
        font-size: 26px;
        margin-bottom: 15px;
        font-weight: 600;
        color: $black-color;
    }
    p {
        margin-bottom: 0;
    }
    ul {
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
            display: block;
            margin-bottom: 18px;

            i {
                display: inline-block;
                font-size: 20px;
                position: relative;
                bottom: -2px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
/*----- End Privacy Policy CSS -----*/

.navbar-brand {
    img {
        max-height: 50px;
    }
}















